<template>
  <div :class="$style.container">
    <div v-for="item in orderPositions" :key="item.id" :class="$style.content">
      <v-image
        :key="`product-media-${media(item)[0].id}`"
        :class="$style.image"
        :src="media(item)[0].content_url"
        :srcset="media(item)[0].preview_url"
      />
      <span :class="$style.count">x{{ item.count }}</span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VImage from '@elements/VImage.vue'

export default Vue.extend({
  name: 'OrderPositions',
  components: { VImage },
  computed: {
    ...mapGetters('Order', ['orderPositions']),
  },
  methods: {
    media(position) {
      return (
        position?.product?.media_objects ??
        position?.post?.media_objects ??
        (position?.channel?.image ? [position?.channel?.image] : [])
      )
    },
  },
})
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  grid-gap: 18px;
}

.content {
  position: relative;
  width: 90px;
  height: 90px;
}

.count {
  position: absolute;
  bottom: 3px;
  left: 3px;
  padding: 0 6px;
  color: white;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  background-color: $secondary;
  border-radius: 9px 9px 9px 3px;
}

.image {
  display: block;
  height: 100%;
  overflow: hidden;
  border-radius: 6px;
}
</style>
