<template>
  <div>
    <span v-if="isLoading">Перенаправляем на платежную систему yookassa</span>
    <span v-else-if="error">Ошибка при попытке оплаты.</span>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import { vCreditCardName, vLength, vRequired } from '@utils/validations'
import PaymentApi from '@services/payment'

export default Vue.extend({
  name: 'Yookassa',
  components: {},
  props: { redirectUrl: String },
  data() {
    return {
      error: false,
      formData: {
        card: '',
        name: '',
        expiration: '',
        securityCode: '',
      },
      symbolsToken: {
        '#': {
          pattern: /[0-9]/,
        },
      },
      isLoading: false,
      isValid: false,
    }
  },
  computed: {
    ...mapGetters('App', ['appCurrencyCode']),
    ...mapGetters('Order', ['order']),
    ...mapGetters('User', ['user']),
    ...mapGetters('Locale', ['locale']),
    rules() {
      return {
        card: [vRequired(), vLength(16, 16)],
        name: [vRequired(), vCreditCardName(), vLength(2, 30)],
        expiration: [vRequired(), vLength(4, 4)],
        securityCode: [vRequired(), vLength(3, 4)],
      }
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Toasts', ['pushToast']),
    ...mapMutations('Promotional', ['setRedirectUrl']),
    ...mapMutations('Client', ['setHasSubscriptions']),
    async metrika() {
      // eslint-disable-next-line no-undef
      ym(process.env.VUE_APP_YM_COUNTER, 'reachGoal', 'yookassa-redirect')
    },
    async onSubmit() {
      this.isLoading = true
      await this.metrika()
      try {
        const {
          confirm_url: url,
          errors,
          status,
        } = await PaymentApi.postPayment({
          amount: String(this.order.sum),
          currency: this.appCurrencyCode() ?? 'RUB',
          description: `${
            // TODO делаю пока хардкором на русском описание для покупки через киви
            this.order.sum <= 1 ? 'Акция - доступ за 1 рубль' : ''
          } | Заказ: #${String(this.order.id)}`,
          order_id: String(this.order.id),
          redirect_url: `${window.location.origin}${this.redirectUrl}`,
          // refundable: false,
          save_payment_method: true,
          system: 'yookassa',
          test: true,
          locale: this.locale === 'en' ? 'en_US' : 'ru_RU',
        })
        if (!errors && status !== 'canceled') {
          this.setHasSubscriptions(true)
          this.setDrawer({ name: 'payment', isVisible: false })
          this.setRedirectUrl(this.redirectUrl)

          if (url) {
            document.location.href = url
          } else if (!this.user) {
            this.setDrawer({ name: 'payment', isVisible: false })
            this.setDrawer({
              name: 'auth',
              isVisible: true,
              params: { afterPayment: true },
            })
          } else {
            this.$router.push(this.redirectUrl)
          }
        } else {
          this.pushToast({
            type: 'error',
            title: `${this.$t('errors.title')}`,
            message: `${this.$t('errors.billing.default')}`,
          })
        }
      } catch (error) {
        this.error = error
        this.$notifyServerError(error)
      }

      this.isLoading = false
    },
  },
  created() {
    this.onSubmit()
  },
})
</script>
