<template>
  <div :class="$style.section">
    <order-history />
    <order-details />
    <payment-drawer :redirect-url="'profile/?frompayment=true&section=orders'" />
    <overlay-content-loader :is-loading="pageLoading" />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import PaymentDrawer from '@drawers/list/payment/PaymentDrawer.vue'
import OverlayContentLoader from '@loaders/list/OverlayContentLoader.vue'
import OrderHistory from './list/OrderHistory.vue'
import OrderDetails from './list/OrderDetails.vue'

export default Vue.extend({
  name: 'Order',
  components: {
    OrderDetails,
    OrderHistory,
    PaymentDrawer,
    OverlayContentLoader,
  },
  computed: {
    ...mapGetters('Order', ['order']),
    ...mapGetters('Page', ['pageLoading', 'pageIsFooter']),
    orderRouteId() {
      return Number(this.$route?.params?.id) ?? null
    },
  },
  methods: {
    ...mapActions('Order', ['getOrder']),
    ...mapMutations('Order', ['resetOrderHistories']),
    ...mapMutations('Page', ['setPageTitle', 'setPageIsFooter']),
  },
  async created() {
    this.setPageTitle({
      name: 'orders.order',
      value: this.orderRouteId,
    })
    if (!this.pageIsFooter) {
      this.setPageIsFooter(true)
    }
    if (!this.order) {
      await this.getOrder(this.orderRouteId)
    }
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-template-rows: 1fr min-content;
  width: 100%;
  height: 100%;
}
</style>
