<template>
  <v-drawer name="payment" @close="onClose" @back="onBack">
    <transition-group :class="$style.content" :name="transitionName">
      <v-list
        v-if="!payment"
        key="list"
        :class="$style.payment"
        :list="payments"
        @click="payment = $event"
      />
      <banking-card
        v-if="payment === 'banking-card'"
        key="card"
        :class="$style.bankingCard"
        :redirect-url="redirectUrl"
      />
      <Youkassa
        v-else-if="payment === 'youkassa'"
        key="card"
        :class="$style.bankingCard"
        :redirect-url="redirectUrl"
      />
      <Stripe
        v-else-if="payment === 'stripe'"
        key="card"
        :class="$style.bankingCard"
        :redirect-url="redirectUrl"
      />
    </transition-group>
  </v-drawer>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VDrawer from '@drawers/VDrawer.vue'
import VList from '@elements/VList.vue'
import BankingCard from '@drawers/list/payment/BankingCard.vue'
// import Qiwi from '@drawers/list/payment/Qiwi.vue'
import Youkassa from '@drawers/list/payment/Yookassa.vue'
import Stripe from '@drawers/list/payment/Stripe.vue'

export default Vue.extend({
  name: 'PaymentDrawer',
  components: { VDrawer, VList, BankingCard, Youkassa, Stripe },
  props: { redirectUrl: String },
  data() {
    return {
      payment: 'youkassa',
      payments: [
        { name: 'banking-card', title: this.$t('bankingCards'), icon: 'card' },
        { name: '', title: 'WebMoney', icon: 'webmoney', disabled: true },
        { name: '', title: 'VK Pay', icon: 'vk', disabled: true },
        { name: '', title: 'Qiwi', icon: 'qiwi', disabled: true },
        { name: '', title: 'PayPal', icon: 'paypal', disabled: true },
      ],
    }
  },
  computed: {
    ...mapGetters('Drawers', ['drawer']),
    ...mapGetters('Countries', ['currentCountryCode']),
    ...mapGetters('App', ['appCurrencyCode']),
    transitionName() {
      return !this.payment ? 'scroll-group' : 'scroll-horizontal'
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    onClose() {
      this.payment = ''
    },
    onBack() {
      this.payment = ''
    },
  },
  created() {
    if (this.appCurrencyCode() !== 'RUB') {
      this.payment = 'stripe'
    }
  },
})
</script>

<style lang="scss" module>
.content {
  position: relative;
  overflow: hidden;
  background: $shadow;
  box-shadow: inset 0 1px 0 $secondary-light;
}

.bankingCard {
  width: 100%;
  height: 100%;
  padding: 15px;
}
</style>
