<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <div>
        <h3 :class="$style.status">{{ status }}</h3>
        <p :class="$style.date">{{ date }}</p>
      </div>
      <order-marker size="md" :color="paymentStatus === 1 ? 'default' : 'primary'">
        {{ $t(`orders.paid.${paymentStatus}`) }}
      </order-marker>
    </div>
    <order-positions v-if="orderPositionsLength" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { formatDateToType } from '@utils/formatDate'

import OrderMarker from './OrderMarker.vue'
import OrderPositions from './OrderPositions.vue'

export default Vue.extend({
  name: 'OrderHistory',
  components: { OrderMarker, OrderPositions },
  computed: {
    ...mapGetters('Locale', ['locale']),
    ...mapGetters('Order', ['order', 'orderPositionsLength']),
    status() {
      if (this.order?.type === 2) {
        return this.$t('orders.archived')
      }
      return this.$t('orders.formed')
    },
    date() {
      return this.ucFirst(formatDateToType(this.order?.created, 'MMMM D, H:mm', this.locale))
    },
    paymentStatus() {
      return this.order?.payment_status
    },
  },
  methods: {
    ucFirst(str) {
      return str ? `${str[0].toUpperCase()}${str.slice(1)}` : str
    },
  },
})
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: min-content;
  padding: 21px;
  box-shadow: inset 0 1px 0 $secondary-light;
  row-gap: 18px;
}

.content {
  display: grid;
  grid-template-columns: 1fr auto;
}

.status {
  color: black;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.date {
  color: $secondary-medium;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>
