// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BankingCard_form__IirL{grid-auto-rows:min-content;grid-auto-flow:row}.BankingCard_content_30vEY{display:grid;padding:21px;row-gap:15px}.BankingCard_inner_wBKzK{display:grid;row-gap:9px}.BankingCard_wrapper_1cBkT{display:grid;grid-template-columns:repeat(2,1fr);-moz-column-gap:20px;column-gap:20px}.BankingCard_button_piEQS{display:grid;grid-auto-flow:column;justify-content:center;-moz-column-gap:9px;column-gap:9px}.BankingCard_title_MnZJS{font-weight:500;line-height:12px;text-transform:uppercase}.BankingCard_text_NJBHj,.BankingCard_title_MnZJS{font-size:12px}.BankingCard_text_NJBHj{font-weight:400;line-height:18px}", ""]);
// Exports
exports.locals = {
	"form": "BankingCard_form__IirL",
	"content": "BankingCard_content_30vEY",
	"inner": "BankingCard_inner_wBKzK",
	"wrapper": "BankingCard_wrapper_1cBkT",
	"button": "BankingCard_button_piEQS",
	"title": "BankingCard_title_MnZJS",
	"text": "BankingCard_text_NJBHj"
};
module.exports = exports;
