<template>
  <v-form :class="$style.form" :is-loading="isLoading" color="primary" @submit="onSubmit">
    <mask-input
      v-model="formData.card"
      type="tel"
      :rules="rules.card"
      :title="$t('cardNumber')"
      :placeholder="$t('minMaxNumbers', ['16'])"
      :mask="['#### #### #### ####']"
      :tokens="symbolsToken"
      :autofocus="true"
    />
    <text-input
      placeholder="JOHN DOE"
      :value="formData.name"
      :rules="rules.name"
      :title="$t('nameAndSecondNameOnCard')"
      @input="formData.name = $event.toUpperCase()"
    />
    <div :class="$style.wrapper">
      <mask-input
        v-model="formData.expiration"
        type="tel"
        :rules="rules.expiration"
        :title="$t('expiration')"
        :mask="['## | ##']"
        :tokens="symbolsToken"
        :placeholder="$t('MMYY')"
      />
      <mask-input
        v-model="formData.securityCode"
        type="tel"
        :rules="rules.securityCode"
        :title="$t('securityCode')"
        :mask="['####']"
        :placeholder="$t('enter.securityCode')"
        :tokens="symbolsToken"
      />
    </div>
    <template #button>
      <div :class="$style.button">
        <icon name="shield" width="14" height="14" />
        {{ $t('pay') }}
      </div>
    </template>
  </v-form>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VForm from '@elements/VForm.vue'
import TextInput from '@elements/inputs/list/TextInput.vue'

import { vCreditCardName, vLength, vRequired } from '@utils/validations'
import MaskInput from '@elements/inputs/list/MaskInput.vue'
import PaymentApi from '@services/payment'

export default Vue.extend({
  name: 'BankingCard',
  components: {
    VForm,
    MaskInput,
    TextInput,
  },
  props: { redirectUrl: String },
  data() {
    return {
      formData: {
        card: '',
        name: '',
        expiration: '',
        securityCode: '',
      },
      symbolsToken: {
        '#': {
          pattern: /[0-9]/,
        },
      },
      isLoading: false,
      isValid: false,
    }
  },
  computed: {
    ...mapGetters('App', ['appCurrencyCode']),
    ...mapGetters('Order', ['order']),
    ...mapGetters('User', ['user']),
    rules() {
      return {
        card: [vRequired(), vLength(16, 16)],
        name: [vRequired(), vCreditCardName(), vLength(2, 30)],
        expiration: [vRequired(), vLength(4, 4)],
        securityCode: [vRequired(), vLength(3, 4)],
      }
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Toasts', ['pushToast']),
    ...mapMutations('Promotional', ['setRedirectUrl']),
    ...mapMutations('Client', ['setHasSubscriptions']),
    async onSubmit() {
      this.isLoading = true
      try {
        const {
          confirm_url: url,
          errors,
          status,
        } = await PaymentApi.postPayment({
          amount: String(this.order.sum),
          card: {
            cardholder: this.formData.name.toUpperCase(),
            csc: this.formData.securityCode,
            expiry_month: this.formData.expiration.substr(0, 2),
            expiry_year: `20${this.formData.expiration.substr(2, 2)}`,
            number: this.formData.card,
          },
          currency: this.appCurrencyCode() ?? 'RUB',
          description: '',
          order_id: String(this.order.id),
          redirect_url: `${window.location.origin}${this.redirectUrl}`,
          refundable: false,
          save_payment_method: false,
          system: 'qiwi',
          test: true,
        })
        if (!errors && status !== 'canceled') {
          this.setHasSubscriptions(true)
          this.setDrawer({ name: 'payment', isVisible: false })
          this.setRedirectUrl(this.redirectUrl)

          // eslint-disable-next-line no-undef
          // ym(process.env.VUE_APP_YM_COUNTER, 'reachGoal', 'promotional-payed')

          if (url) {
            document.location.href = url
          } else if (!this.user) {
            this.setDrawer({ name: 'payment', isVisible: false })
            this.setDrawer({
              name: 'auth',
              isVisible: true,
              params: { afterPayment: true },
            })
          } else {
            this.$router.push(this.redirectUrl)
          }
        } else {
          this.pushToast({
            type: 'error',
            title: `${this.$t('errors.title')}`,
            message: `${this.$t('errors.billing.default')}`,
          })
        }
      } catch (error) {
        this.$notifyServerError(error)
      }

      this.isLoading = false
    },
  },
})
</script>
<style lang="scss" module>
.form {
  grid-auto-rows: min-content;
  grid-auto-flow: row;
}

.content {
  display: grid;
  padding: 21px;
  row-gap: 15px;
}

.inner {
  display: grid;
  row-gap: 9px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}

.button {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: 9px;
}

.title {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>
