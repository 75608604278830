<template>
  <div :class="$style.section">
    <h2 :class="$style.header">{{ $t('orders.details') }}:</h2>
    <div :class="$style.container">
      <h3 :class="$style.title">{{ $t('receiver') }}:</h3>
      <div :class="$style.content">
        <p :class="$style.text">{{ name }} {{ surname }} {{ patronymic }}</p>
        <p v-if="email" :class="$style.text">{{ email }}</p>
        <p v-if="phone" :class="$style.text">+{{ phone }}</p>
        <p v-if="!name && !email && !phone" :class="$style.text">. . .</p>
      </div>
    </div>
    <div :class="$style.container">
      <h3 :class="$style.title">{{ $t('payment') }}:</h3>
      <div :class="$style.content">
        <p :class="$style.title">{{ sum }} {{ currency }}</p>
      </div>
    </div>
    <div v-if="!!address" :class="$style.container">
      <h3 :class="$style.title">{{ $t(`orders.delivery.types.${deliveryMethod}`) }}:</h3>
      <div :class="$style.content">
        <p :class="$style.text">{{ address || '. . .' }}</p>
      </div>
    </div>
    <v-button v-if="isPay" color="primary" size="xl" @click="onPay">{{ $t('pay') }}</v-button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'OrderDetails',
  components: { VButton },
  computed: {
    ...mapGetters('Order', ['order']),
    name() {
      return this.order?.recipient?.name ?? this.order?.client?.name
    },
    patronymic() {
      return this.order?.recipient?.patronymic
    },
    surname() {
      return this.order?.recipient?.surname
    },
    sum() {
      return this.order?.sum
    },
    currency() {
      return this.order?.app?.currency?.symbol
    },
    email() {
      return this.order?.recipient?.email
    },
    phone() {
      return this.order?.recipient?.phone.toLocaleString('Ru-ru')
    },
    address() {
      return this.order?.recipient?.address
    },
    deliveryMethod() {
      return this.order?.delivery_method ?? 1
    },
    isPay() {
      return this.order?.status === 1 && this.order?.payment_status !== 2
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    onPay() {
      this.setDrawer({ name: 'payment', isVisible: true })
    },
  },
})
</script>

<style lang="scss" module>
.section {
  display: grid;
  padding: 21px;
  background-color: $shadow;
  box-shadow: inset 0 1px 0 $secondary-light;
  row-gap: 12px;
}

.header {
  color: black;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

.container {
  display: grid;
  grid-template-columns: 90px 1fr;
  padding: 12px 0;
  column-gap: 5px;
  border-top: 1px solid $secondary-light;
}

.title {
  color: black;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.content {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: min-content;
  row-gap: 3px;

  .text {
    color: black;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}
</style>
