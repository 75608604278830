// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PaymentDrawer_content_1Grkp{position:relative;overflow:hidden;background:#f8f8f8;box-shadow:inset 0 1px 0 #e5e3e3}.PaymentDrawer_bankingCard_1PpCS{width:100%;height:100%;padding:15px}", ""]);
// Exports
exports.locals = {
	"content": "PaymentDrawer_content_1Grkp",
	"bankingCard": "PaymentDrawer_bankingCard_1PpCS"
};
module.exports = exports;
