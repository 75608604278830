// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrderPositions_container_2RIj6{display:grid;grid-auto-columns:min-content;grid-auto-flow:column;grid-gap:18px}.OrderPositions_content_3gwTP{position:relative;width:90px;height:90px}.OrderPositions_count_ZOE2C{position:absolute;bottom:3px;left:3px;padding:0 6px;color:#fff;font-weight:500;font-size:11px;line-height:18px;background-color:var(--supster-secondary);border-radius:9px 9px 9px 3px}.OrderPositions_image_3cDEZ{display:block;height:100%;overflow:hidden;border-radius:6px}", ""]);
// Exports
exports.locals = {
	"container": "OrderPositions_container_2RIj6",
	"content": "OrderPositions_content_3gwTP",
	"count": "OrderPositions_count_ZOE2C",
	"image": "OrderPositions_image_3cDEZ"
};
module.exports = exports;
