// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrderDetails_section_3V9_R{display:grid;padding:21px;background-color:#f8f8f8;box-shadow:inset 0 1px 0 #e5e3e3;row-gap:12px}.OrderDetails_header_3QKH3{color:#000;font-weight:500;font-size:15px;line-height:22px}.OrderDetails_container_2TRz1{display:grid;grid-template-columns:90px 1fr;padding:12px 0;-moz-column-gap:5px;column-gap:5px;border-top:1px solid #e5e3e3}.OrderDetails_title_3ZWU9{color:#000;font-weight:500;font-size:12px;line-height:18px}.OrderDetails_content_1B8c_{display:grid;grid-auto-flow:row;grid-template-rows:min-content;row-gap:3px}.OrderDetails_content_1B8c_ .OrderDetails_text_R-byH{color:#000;font-weight:400;font-size:12px;line-height:18px}", ""]);
// Exports
exports.locals = {
	"section": "OrderDetails_section_3V9_R",
	"header": "OrderDetails_header_3QKH3",
	"container": "OrderDetails_container_2TRz1",
	"title": "OrderDetails_title_3ZWU9",
	"content": "OrderDetails_content_1B8c_",
	"text": "OrderDetails_text_R-byH"
};
module.exports = exports;
