// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrderHistory_container_1bUmN{display:grid;grid-auto-flow:row;grid-template-rows:min-content;padding:21px;box-shadow:inset 0 1px 0 #e5e3e3;row-gap:18px}.OrderHistory_content_Olu8B{display:grid;grid-template-columns:1fr auto}.OrderHistory_status_1_J3h{color:#000;font-weight:500}.OrderHistory_date_LVCnG,.OrderHistory_status_1_J3h{font-size:12px;line-height:18px}.OrderHistory_date_LVCnG{color:#a09f9f;font-weight:400}", ""]);
// Exports
exports.locals = {
	"container": "OrderHistory_container_1bUmN",
	"content": "OrderHistory_content_Olu8B",
	"status": "OrderHistory_status_1_J3h",
	"date": "OrderHistory_date_LVCnG"
};
module.exports = exports;
