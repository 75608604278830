/* eslint-disable camelcase */
import { axiosBilling } from '@plugins/axios'
import { DataType } from './types'

export default {
  getPayments: async (): Promise<DataType[]> => {
    const { data }: ResType<DataType[]> = await axiosBilling.get('payments')
    return data === null ? [] : data
  },
  postPayment: async (payment: DataType): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosBilling.post('payments', payment)
    return data
  },
}
