<template>
  <transition name="fade-kit">
    <div v-if="isLoading" :class="$style.container">
      <content-loader :is-loading="isLoading" :size="size" :delay="delay" />
    </div>
  </transition>
</template>
<script>
import Vue from 'vue'

import ContentLoader from '@loaders/list/ContentLoader.vue'

export default Vue.extend({
  name: 'OverlayContentLoader',
  components: { ContentLoader },
  props: {
    delay: Number,
    size: {
      default: 40,
      type: Number,
    },
    isLoading: Boolean,
  },
})
</script>
<style lang="scss" module>
.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(white, 0.8);
}
</style>
